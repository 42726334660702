import React from "react";
import {
    SidebarManager
} from "@zandor300/backoffice-framework";

import {
    AppleMapsManager
} from "./components/apple-maps/AppleMapsManager";

function AppManager({ children }) {
    return (
        <SidebarManager>
            <AppleMapsManager>
                { children }
            </AppleMapsManager>
        </SidebarManager>
    )
}

export default React.memo(AppManager);
