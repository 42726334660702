import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import ManualInverterStateSettingModal from "./modal/ManualInverterStateSettingModal";
import InstallationAddDeviceModal from "./modal/InstallationAddDeviceModal";
import {
    InstallationInverterCard
} from "./components/device-cards/InstallationInverterCards";
import {
    InstallationBatteryCard
} from "./components/device-cards/InstallationBatteryCards";
import {
    InstallationEnergyMeterCard
} from "./components/device-cards/InstallationEnergyMeterCards";
import {
    InstallationEVChargerCard
} from "./components/device-cards/InstallationEVChargerCards";
import {
    InstallationRelayCard
} from "./components/device-cards/InstallationRelayCards";
import EnergyAlgorithmSettingModal from "./modal/EnergyAlgorithmSettingModal";
import RelaySettingsModal from "./modal/relay-settings/RelaySettingsModal";
import EVChargerModeModal from "./modal/EVChargerModeModal";
import BatterySetRatedEnergyModal from "./modal/battery-settings/BatterySetRatedEnergyModal";

function InstallationDetailDevices({ installation }) {
    const [inverters, setInverters] = useState(null);
    const [batteries, setBatteries] = useState(null);
    const [energyMeters, setEnergyMeters] = useState(null);
    const [evChargers, setEVChargers] = useState(null);
    const [relays, setRelays] = useState(null);

    const [error, setError] = useState(null);

    const [showManualInverterStateSettingModal, setShowManualInverterStateSettingModal] = useState(false);
    const [manualInverterStateSettingModalInverter, setManualInverterStateSettingModalInverter] = useState(null);

    const [showInverterEnergyAlgorithmSettingModal, setShowInverterEnergyAlgorithmSettingModal] = useState(false);
    const [inverterEnergyAlgorithmSettingModalInverter, setInverterEnergyAlgorithmSettingModalInverter] = useState(null);

    const [showRelaySettingsModal, setShowRelaySettingsModal] = useState(false);
    const [relaySettingsModalRelay, setRelaySettingsModalRelay] = useState(null);

    const [showEVChargerModeModal, setShowEVChargerModeModal] = useState(false);
    const [evChargerModeModalEVCharger, setEVChargerModeModalEVCharger] = useState(null);

    const [showBatterySetRatedEnergyModal, setShowBatterySetRatedEnergyModal] = useState(false);
    const [batterySetRatedEnergyModalBattery, setBatterySetRatedEnergyModalBattery] = useState(null);

    const [showDeviceModal, setShowDeviceModal] = useState(false);

    const refreshInverters = useCallback(() => {
        if(!installation) {
            return;
        }
        setError(null);
        axios.post("/getDevicesForInstallation", { installationId: installation.id })
            .then((response) => {
                if(response.data.valid) {
                    setInverters(response.data.inverters);
                    setBatteries(response.data.batteries);
                    setEnergyMeters(response.data.energyMeters);
                    setEVChargers(response.data.evChargers);
                    setRelays(response.data.relays);
                } else {
                    setError("Er is iets fout gegaan. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            });
    }, [installation]);

    useEffect(() => {
        refreshInverters();
        const interval = setInterval(refreshInverters, 5000);
        return () => clearInterval(interval);
    }, [refreshInverters]);

    const onClickInverterState = useMemo(() => {
        if(!installation?.userRole?.permissions.manageInstallationDeviceEnergyHandlingModes) {
            return null;
        }
        return (inverter) => {
            setManualInverterStateSettingModalInverter(inverter);
            setShowManualInverterStateSettingModal(true);
        };
    }, [installation]);
    const onClickInverterEnergyAlgorithm = useMemo(() => {
        if(!installation?.userRole?.permissions.manageInstallationDevices) {
            return null;
        }
        return (inverter) => {
            setInverterEnergyAlgorithmSettingModalInverter(inverter);
            setShowInverterEnergyAlgorithmSettingModal(true);
        };
    }, [installation]);
    const onClickRelaySettings = useMemo(() => {
        if(!installation?.userRole?.permissions.manageInstallationDeviceEnergyHandlingModes) {
            return null;
        }
        return (relay) => {
            setRelaySettingsModalRelay(relay);
            setShowRelaySettingsModal(true);
        };
    }, [installation]);
    const onClickEVChargerMode = useMemo(() => {
        if(!installation?.userRole?.permissions.manageInstallationDeviceEnergyHandlingModes) {
            return null;
        }
        return (evCharger) => {
            setEVChargerModeModalEVCharger(evCharger);
            setShowEVChargerModeModal(true);
        };
    }, [installation]);
    const onClickSetBatteryRatedEnergy = useMemo(() => {
        if(!installation?.userRole?.permissions.manageInstallationDevices) {
            return null;
        }
        return (battery) => {
            setBatterySetRatedEnergyModalBattery(battery);
            setShowBatterySetRatedEnergyModal(true);
        };
    }, [installation]);

    useEffect(() => {
        setRelaySettingsModalRelay((prevRelay) => {
            if(!prevRelay) {
                return prevRelay;
            }
            return relays?.find((relay) => relay.id === prevRelay.id);
        });
    }, [relays]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        );
    }
    if(!inverters && !batteries && !energyMeters && !evChargers) {
        return (
            <Loading/>
        )
    }
    if(
        inverters?.length === 0
        && batteries?.length === 0
        && energyMeters?.length === 0
        && evChargers?.length === 0
        && relays?.length === 0
    ) {
        return (
            <div className="text-center">
                { installation?.userRole?.permissions.manageInstallationDevices && (
                    <InstallationAddDeviceModal
                        show={ showDeviceModal }
                        handleClose={ () => setShowDeviceModal(false) }
                        installation={ installation }
                    />
                )}
                <h1><i className="fa-solid fa-solar-panel"/></h1>
                <h4>Geen apparaten</h4>
                <p>Er zijn nog geen apparaten toegevoegd aan deze installatie.</p>
                { installation?.userRole?.permissions.manageInstallationDevices && (
                    <Button
                        variant="primary"
                        onClick={ () => setShowDeviceModal(true) }
                    >
                        <i className="fa-solid fa-plus mr-2"/>
                        Apparaat toevoegen
                    </Button>
                )}
            </div>
        )
    }
    return (
        <React.Fragment>
            { installation?.userRole?.permissions.manageInstallationDeviceEnergyHandlingModes && (
                <React.Fragment>
                    <ManualInverterStateSettingModal
                        show={ showManualInverterStateSettingModal }
                        inverter={ manualInverterStateSettingModalInverter }
                        handleClose={ () => setShowManualInverterStateSettingModal(false) }
                        refreshInverters={ refreshInverters }
                    />
                    <RelaySettingsModal
                        show={ showRelaySettingsModal }
                        relay={ relaySettingsModalRelay }
                        handleClose={ () => setShowRelaySettingsModal(false) }
                        onSaved={ refreshInverters }
                    />
                    <EVChargerModeModal
                        show={ showEVChargerModeModal }
                        evCharger={ evChargerModeModalEVCharger }
                        handleClose={ () => setShowEVChargerModeModal(false) }
                        refreshEVChargers={ refreshInverters }
                    />
                </React.Fragment>
            )}
            { installation?.userRole?.permissions.manageInstallationDevices && (
                <React.Fragment>
                    <InstallationAddDeviceModal
                        show={ showDeviceModal }
                        handleClose={ () => setShowDeviceModal(false) }
                        installation={ installation }
                    />
                    <EnergyAlgorithmSettingModal
                        show={ showInverterEnergyAlgorithmSettingModal }
                        inverter={ inverterEnergyAlgorithmSettingModalInverter }
                        handleClose={ () => setShowInverterEnergyAlgorithmSettingModal(false) }
                        refreshInverters={ refreshInverters }
                    />
                    <BatterySetRatedEnergyModal
                        show={ showBatterySetRatedEnergyModal }
                        battery={ batterySetRatedEnergyModalBattery }
                        handleClose={ () => setShowBatterySetRatedEnergyModal(false) }
                        refreshBatteries={ refreshInverters }
                    />
                </React.Fragment>
            )}
            { installation?.userRole?.permissions.manageInstallationDevices && (
                <div className="d-flex justify-content-end">
                    <Button
                        variant="primary"
                        className="mb-3"
                        size="sm"
                        onClick={ () => setShowDeviceModal(true) }
                    >
                        <i className="fa-solid fa-plus mr-2"/>
                        Apparaat toevoegen
                    </Button>
                </div>
            )}
            <div className="row">
                { inverters?.length > 0 && (
                    inverters.map((inverter) => (
                        <div className="col-12 col-lg-6 col-xxl-4" key={ inverter.id }>
                            <InstallationInverterCard
                                inverter={ inverter }
                                onClickInverterState={ onClickInverterState }
                                onClickInverterEnergyAlgorithm={ onClickInverterEnergyAlgorithm }
                                allowHistory
                            />
                        </div>
                    ))
                )}
                { batteries?.length > 0 && (
                    batteries.map((battery) => (
                        <div className="col-12 col-lg-6 col-xxl-4" key={ battery.id }>
                            <InstallationBatteryCard
                                battery={battery}
                                installation={installation}
                                onClickSetBatteryRatedEnergy={ onClickSetBatteryRatedEnergy }
                                allowHistory
                            />
                        </div>
                    ))
                )}
                { energyMeters?.length > 0 && (
                    energyMeters.map((energyMeter) => (
                        <div className="col-12 col-lg-6 col-xxl-4" key={ energyMeter.id }>
                            <InstallationEnergyMeterCard
                                energyMeter={ energyMeter }
                                allowHistory
                            />
                        </div>
                    ))
                )}
                { evChargers?.length > 0 && (
                    evChargers.map((evCharger) => (
                        <div className="col-12 col-lg-6 col-xxl-4" key={ evCharger.id }>
                            <InstallationEVChargerCard
                                evCharger={ evCharger }
                                onClickEVChargerMode={ onClickEVChargerMode }
                            />
                        </div>
                    ))
                )}
                { relays?.length > 0 && (
                    relays.map((relay) => (
                        <div className="col-12 col-lg-6 col-xxl-4" key={ relay.id }>
                            <InstallationRelayCard
                                relay={ relay }
                                onClickSettings={ onClickRelaySettings }
                            />
                        </div>
                    ))
                )}
            </div>
        </React.Fragment>
    )
}

export default React.memo(InstallationDetailDevices);
