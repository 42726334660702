import React, {
    useContext,
    useMemo
} from "react";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";

import Loading from "../../components/Loading";
import DateFormatter from "../../components/formatters/DateFormatter";
import InstallationTypeIcon from "../../components/InstallationTypeIcon";
import usePermissions from "../../hooks/usePermissions";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

function InstallationsTable({ installations, searchQuery, error }) {
    const authentication = useContext(AuthenticatedUserContext);
    const permissions = usePermissions();

    const showCompanyName = useMemo(() => {
        if(permissions.globalAdmin) {
            return true;
        }
        return authentication.user?.companies?.length > 2;
    }, [authentication, permissions.globalAdmin]);

    return (
        <Table hover={ installations?.length > 0 }>
            <thead>
            <tr className="tr-sticky">
                <th className="text-center">#</th>
                <th className="d-none d-md-table-cell text-center">Type</th>
                <th className="d-none d-md-table-cell">Naam</th>
                <th className="d-none d-md-table-cell">Adres</th>
                <th className="d-none d-lg-table-cell text-right">Laatst bijgewerkt</th>
                <th className="d-none d-xl-table-cell text-center">Online apparaten</th>
                { showCompanyName && (
                    <th className="d-none d-xl-table-cell text-left">Bedrijf</th>
                )}
                <th className="d-md-none">Installatie</th>
            </tr>
            </thead>
            <tbody>
            { error ? (
                <tr>
                    <td colSpan={ 8 }>
                        <Alert variant="danger">{ error }</Alert>
                    </td>
                </tr>
            ) : !installations ? (
                <tr>
                    <td colSpan={ 8 }>
                        <Loading/>
                    </td>
                </tr>
            ) : installations.length === 0 ? (
                <tr>
                    { searchQuery.trim().length === 0 ? (
                        permissions.manageInstallations ? (
                            <td colSpan={ 8 } className="text-center">
                                <h1><i className="fa-solid fa-solar-panel"/></h1>
                                <h4>Geen installaties</h4>
                                <p>Maak je eerste installatie aan!</p>
                                <Link
                                    to="/installations/add"
                                    className="btn btn-primary btn-sm"
                                >
                                    <i className="fa-solid fa-plus mr-2"/>
                                    Installatie aanmaken
                                </Link>
                            </td>
                        ) : (
                            <td colSpan={ 8 } className="text-center">
                                <h1><i className="fa-solid fa-solar-panel"/></h1>
                                <h4>Geen installaties</h4>
                                <p>Je hebt momenteel geen toegang tot een installatie.</p>
                            </td>
                        )
                    ) : (
                        <td colSpan={ 8 } className="text-center">
                            <h1><i className="fa-solid fa-solar-panel"/></h1>
                            <h4>Geen installaties gevonden</h4>
                            <p>Er zijn geen installaties gevonden die voldoen aan je zoekopdracht.</p>
                        </td>
                    )}
                </tr>
            ) : installations.map((installation) => {
                const url = `/installation/${installation.id}`;
                const offline = installation.lastUpdateDate && moment(installation.lastUpdateDate) < moment().subtract(30, "minutes");
                return (
                    <tr key={ installation.id } className="table-clickable-row">
                        <td className="align-middle text-center">
                            <Link to={ url }>
                                { installation.id }
                            </Link>
                        </td>
                        <td className="d-none d-md-table-cell align-middle text-center">
                            <Link to={ url }>
                                <InstallationTypeIcon
                                    type={ installation.type }
                                    className="text-muted"
                                />
                            </Link>
                        </td>
                        <td className="d-none d-md-table-cell align-middle">
                            <Link to={ url }>
                                <b>{ installation.name }</b>
                            </Link>
                        </td>
                        <td className="d-none d-md-table-cell align-middle">
                            <Link to={ url }>
                                { installation.address }, { installation.city }
                            </Link>
                        </td>
                        <td className="d-none d-lg-table-cell align-middle text-right">
                            <Link to={ url } className={ offline ? "text-danger" : "" }>
                                { installation.lastUpdateDate ? (
                                    <DateFormatter date={ installation.lastUpdateDate }/>
                                ) : (<i className="text-muted">Nooit</i>)}
                            </Link>
                        </td>
                        <td className="d-none d-xl-table-cell align-middle text-center">
                            <Link to={ url } className={ offline ? "text-danger" : "" }>
                                { installation.devicesTotal > 0 ? (
                                    <span className={ installation.devicesTotal > installation.devicesOnline ? "text-danger" : "text-success" }>
                                            { installation.devicesOnline } / { installation.devicesTotal }
                                        </span>
                                ) : (<i className="text-muted">Geen apparaten</i>)}
                            </Link>
                        </td>
                        { showCompanyName && (
                            <td className="d-none d-xl-table-cell align-middle text-left">
                                <Link to={ url }>
                                    { installation.company?.name }
                                </Link>
                            </td>
                        )}
                        <td className="d-md-none align-middle">
                            <Link to={ url }>
                                <InstallationTypeIcon
                                    type={ installation.type }
                                    className="text-muted mr-2"
                                />
                                <b>
                                    { installation.name }
                                </b>
                                <br/>
                                <span className="text-muted">
                                        { installation.address }, { installation.city }
                                    <br/>
                                        <small className={ offline ? "text-danger" : "" }>
                                            Bijgewerkt:{" "}
                                            { installation.lastUpdateDate ? (
                                                <DateFormatter date={ installation.lastUpdateDate }/>
                                            ) : ("Nooit") }
                                        </small>
                                    </span>
                            </Link>
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </Table>
    );
}

export default React.memo(InstallationsTable);
