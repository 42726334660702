import React, {
    createContext
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Buffer
} from "buffer";

const AppleMapsContext = createContext(null);

export class AppleMapsManager extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            error: null,
            refreshTokenIfNeeded: this.refreshTokenIfNeeded.bind(this)
        }
        this.expires = null;
        this.loading = false;
    }

    componentDidMount() {
        this.getToken();
    }

    getRequestHeaders() {
        const token = this.getMonitoringToken();
        return token ? { Token: token } : {};
    }

    refreshTokenIfNeeded() {
        if(!this.state.token || !this.expires) {
            this.getToken();
        }
        if(moment.unix(this.expires) < moment()) {
            this.getToken();
        }
    }

    getJTWExpireDate(token) {
        const bodyBase64 = token.split(".")[1];
        const body = JSON.parse(Buffer.from(bodyBase64, "base64"));
        return body.exp;
    }

    getToken() {
        if(this.loading) {
            return;
        }
        this.loading = true;
        this.setState({ token: null });
        
        axios.get("/getAppleMapsToken")
            .then((response) => {
                if(response.data.valid) {
                    const token = response.data.token;

                    this.setState({ token });

                    this.expires = this.getJTWExpireDate(token);
                    console.log("Apple Maps Token expires at: " + moment.unix(this.expires).format("YYYY-MM-DD HH:MM:SS"));
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    render() {
        return (
            <AppleMapsContext.Provider value={ this.state }>
                { this.props.children }
            </AppleMapsContext.Provider>
        )
    }
}

export default AppleMapsContext;
