import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import moment from "moment";
import axios from "axios";
import {
    Alert,
    Modal
} from "react-bootstrap";

import ChartDateSwitcher from "../../../energy-prices/components/ChartDateSwitcher";
import ChartLoading from "../../../../components/charts/ChartLoading";
import ChartNoData from "../../../../components/charts/ChartNoData";
import ChartCard from "../../../../components/charts/ChartCard";
import GridSenseChart from "../../../../components/charts/GridSenseChart";

function InverterHistoryModal({ show, handleClose, inverter }) {
    const [inverterId, setInverterId] = useState(null);
    const [date, setDate] = useState(moment().tz("Europe/Amsterdam", true));
    const [history, setHistory] = useState(null);
    const [error, setError] = useState(null);

    const onDateChange = useCallback((newDate) => {
        setDate(newDate);
    }, []);
    const chartDateRange = useMemo(() => {
        return {
            startDate: date.clone().startOf("day"),
            endDate: date.clone().endOf("day")
        };
    }, [date]);

    useEffect(() => {
        if(!show) {
            return;
        }
        onDateChange(moment().tz("Europe/Amsterdam", true));
    }, [show, onDateChange]);
    useEffect(() => {
        setInverterId(inverter.id);
    }, [inverter]);
    const refreshHistory = useCallback(async () => {
        if(!inverterId) {
            return;
        }
        setHistory(null);
        setError(null);
        try {
            const response = await axios.post("/getInverterHistory", {
                inverterId,
                startDate: chartDateRange.startDate.toISOString(),
                endDate: chartDateRange.endDate.toISOString()
            });
            setHistory(response.data.history);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [inverterId, chartDateRange]);
    useEffect(() => {
        if(!show) {
            return;
        }
        refreshHistory();
    }, [show, refreshHistory]);

    const injectedAbsorbedData = useMemo(() => {
        if(!history) {
            return null;
        }
        if(history.length === 0 || history.filter(({ energyInjected, energyAbsorbed }) => {
            return energyInjected > 0 || energyAbsorbed > 0;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "Geleverd",
                data: history.map(({ date, energyInjectedAvgPower }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: energyInjectedAvgPower,
                }))
            },
            {
                label: "Verbruikt",
                data: history.map(({ date, energyAbsorbedAvgPower }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: -energyAbsorbedAvgPower,
                }))
            },
        ];
    }, [history]);
    const pvPowerData = useMemo(() => {
        const maxPvIndex = 6;
        if(!history) {
            return null;
        }
        if(history.length === 0 || history.filter((historyItem) => {
            for(let i = 1; i <= maxPvIndex; i++) {
                if(historyItem[`powerDcPv${i}`] !== null) {
                    return true;
                }
            }
            return false;
        }).length === 0) {
            return [];
        }
        return [...Array(maxPvIndex)].map((value, index) => {
            const pvIndex = index + 1;
            let allNull = true;
            const series = {
                label: `PV Vermogen ${pvIndex}`,
                data: history.map(({ date, [`powerDcPv${pvIndex}`]: powerDcPv }) => {
                    if(powerDcPv !== null) {
                        allNull = false;
                    }
                    return {
                        key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                        value: powerDcPv,
                    };
                }),
            };
            return allNull ? null : series;
        }).filter((series) => series !== null);
    }, [history]);
    const pvVoltageData = useMemo(() => {
        const maxPvIndex = 6;
        if(!history) {
            return null;
        }
        if(history.length === 0 || history.filter((historyItem) => {
            for(let i = 1; i <= maxPvIndex; i++) {
                if(historyItem[`voltageDcPv${i}`] !== null) {
                    return true;
                }
            }
            return false;
        }).length === 0) {
            return [];
        }
        return [...Array(maxPvIndex)].map((value, index) => {
            const pvIndex = index + 1;
            let allNull = true;
            const series = {
                label: `PV Spanning ${pvIndex}`,
                data: history.map(({ date, [`voltageDcPv${pvIndex}`]: voltageDcPv }) => {
                    if(voltageDcPv !== null) {
                        allNull = false;
                    }
                    return {
                        key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                        value: voltageDcPv,
                    };
                }),
            };
            return allNull ? null : series;
        }).filter((series) => series !== null);
    }, [history]);
    const acVoltageData = useMemo(() => {
        if(!history) {
            return null;
        }
        if(history.length === 0 || history.filter(({ voltageAcL1, voltageAcL2, voltageAcL3 }) => {
            return voltageAcL1 !== null || voltageAcL2 !== null || voltageAcL3 !== null;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "AC Spanning L1",
                data: history.map(({ date, voltageAcL1 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL1
                }))
            }, {
                label: "AC Spanning L2",
                data: history.map(({ date, voltageAcL2 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL2
                }))
            }, {
                label: "AC Spanning L3",
                data: history.map(({ date, voltageAcL3 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL3
                }))
            }
        ];
    }, [history]);
    const acVoltagePhaseData = useMemo(() => {
        if(!history) {
            return null;
        }
        if(history.length === 0 || history.filter(({ voltageAcL1L2, voltageAcL2L3, voltageAcL3L1 }) => {
            return voltageAcL1L2 !== null || voltageAcL2L3 !== null || voltageAcL3L1 !== null;
        }).length === 0) {
            return [];
        }
        return [
            {
                label: "AC Spanning L1-L2",
                data: history.map(({ date, voltageAcL1L2 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL1L2
                }))
            }, {
                label: "AC Spanning L2-L3",
                data: history.map(({ date, voltageAcL2L3 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL2L3
                }))
            }, {
                label: "AC Spanning L3-L1",
                data: history.map(({ date, voltageAcL3L1 }) => ({
                    key: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    value: voltageAcL3L1
                }))
            }
        ];
    }, [history]);
    const primaryAxis = useMemo(() => ({
        getValue: (datum) => moment(datum.key).toDate(),
        formatters: {
            scale: (key) => moment(key).format("HH:mm")
        },
        min: moment(chartDateRange.startDate).toDate(),
        max: moment(chartDateRange.endDate).toDate()
    }), [chartDateRange]);
    const injectedAbsorbedSecondaryAxes = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${(value * 0.001)?.toFixed(2)} kW`,
            tooltip: (value) => `${(value * 0.001)?.toFixed(2)} kW`
        },
        stacked: true,
    }], []);
    const pvPowerSecondaryAxes = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${(value * 0.001)?.toFixed(2)} kW`,
            tooltip: (value) => `${(value * 0.001)?.toFixed(2)} kW`
        },
    }], []);
    const voltageSecondaryAxes = useMemo(() => [{
        getValue: (datum) => datum.value,
        formatters: {
            scale: (value) => `${value?.toFixed(0)} V`,
            tooltip: (value) => `${value?.toFixed(1)} V`
        },
    }], []);

    return (
        <Modal show={ show } onHide={ handleClose } size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Omvormer data
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card mb-3">
                    <div className="card-body">
                        <ChartDateSwitcher
                            date={date}
                            setDate={onDateChange}
                        />
                    </div>
                </div>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : (
                    <React.Fragment>
                        <ChartCard title="AC Vermogen (kW)">
                            { !injectedAbsorbedData ? (
                                <ChartLoading/>
                            ) : injectedAbsorbedData.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <GridSenseChart
                                    options={{
                                        data: injectedAbsorbedData,
                                        primaryAxis,
                                        secondaryAxes: injectedAbsorbedSecondaryAxes
                                    }}
                                />
                            )}
                        </ChartCard>
                        <ChartCard title="PV DC Vermogen (kW)">
                            { !pvPowerData ? (
                                <ChartLoading/>
                            ) : pvPowerData.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <GridSenseChart
                                    options={{
                                        data: pvPowerData,
                                        primaryAxis,
                                        secondaryAxes: pvPowerSecondaryAxes,
                                    }}
                                />
                            )}
                        </ChartCard>
                        <ChartCard title="PV DC Spanning (V)">
                            { !pvVoltageData ? (
                                <ChartLoading/>
                            ) : pvVoltageData.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <GridSenseChart
                                    options={{
                                        data: pvVoltageData,
                                        primaryAxis,
                                        secondaryAxes: voltageSecondaryAxes,
                                    }}
                                />
                            )}
                        </ChartCard>
                        <ChartCard title="AC Spanning (L-N) (V)">
                            { !acVoltageData ? (
                                <ChartLoading/>
                            ) : acVoltageData.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <GridSenseChart
                                    options={{
                                        data: acVoltageData,
                                        primaryAxis,
                                        secondaryAxes: voltageSecondaryAxes
                                    }}
                                />
                            )}
                        </ChartCard>
                        <ChartCard title="AC Spanning (L-L) (V)">
                            { !acVoltagePhaseData ? (
                                <ChartLoading/>
                            ) : acVoltagePhaseData.length === 0 ? (
                                <ChartNoData/>
                            ) : (
                                <GridSenseChart
                                    options={{
                                        data: acVoltagePhaseData,
                                        primaryAxis,
                                        secondaryAxes: voltageSecondaryAxes
                                    }}
                                />
                            )}
                        </ChartCard>
                    </React.Fragment>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default React.memo(InverterHistoryModal);
