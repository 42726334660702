import React, {
    useMemo
} from "react";
import {
    Alert
} from "react-bootstrap";
import {
    ErrorBoundary
} from "@sentry/react";
import {
    Annotation
} from "@zandor300/react-apple-mapkitjs";

import AppleMapsMap from "../../components/apple-maps/AppleMapsMap";
import getCenterCoordinate from "../../components/apple-maps/getCenterCoordinate";
import Loading from "../../components/Loading";

function InstallationsMap({ installations, error }) {
    const center = useMemo(() => {
        if(!installations) {
            return {
                centerLatitude: 52.2129919,
                centerLongitude: 5.2793703,
                latitudeSpan: 20,
                longitudeSpan: 20,
            };
        }
        return getCenterCoordinate(installations);
    }, [installations]);
    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        );
    }
    if(!installations) {
        return (
            <Loading/>
        );
    }
    return (
        <ErrorBoundary fallback={
            <Alert variant="danger">Er is een fout opgetreden.</Alert>
        }>
            <AppleMapsMap
                height="800px"
                latitude={ center?.centerLatitude ? center.centerLatitude : 52.2129919  }
                longitude={ center?.centerLongitude ? center.centerLongitude : 5.2793703 }
                spanLat={ center?.latitudeSpan ? center.latitudeSpan : 3 }
                spanLong={ center?.longitudeSpan ? center.longitudeSpan : 4 }
            >
                { installations?.map((installation) => (
                    <Annotation
                        key={ installation.id }
                        id={ installation.id }
                        latitude={ installation.latitude }
                        longitude={ installation.longitude }
                        title={ installation.name }
                    />
                )) }
            </AppleMapsMap>
        </ErrorBoundary>
    );
}

export default React.memo(InstallationsMap);
