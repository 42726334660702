import React from "react";
import moment from "moment";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import usePermissions from "../../hooks/usePermissions";
import ChartCard from "../../components/charts/ChartCard";
import ChartEnergyPrices from "../../charts/ChartEnergyPrices";
import DashboardTotalEnergyFlowCard from "./components/DashboardTotalEnergyFlowCard";

function Dashboard() {
    const permissions = usePermissions();
    const now = moment().tz("Europe/Amsterdam", true);
    return (
        <React.Fragment>
            <Helmet title="Dashboard"/>
            <Title preTitle="Overzicht">
                Dashboard
            </Title>
            <div className="row">
                <div className="col-lg-6">
                    <ChartCard title="Dynamische energieprijs vandaag (excl. btw)">
                        <ChartEnergyPrices
                            startDate={ now.clone().startOf("day") }
                            endDate={ now.clone().endOf("day") }
                        />
                    </ChartCard>
                </div>
                <div className="col-lg-6">
                    <ChartCard title="Dynamische energieprijs morgen (excl. btw)">
                        <ChartEnergyPrices
                            startDate={ now.clone().startOf("day").add(1, "day") }
                            endDate={ now.clone().endOf("day").add(1, "day") }
                        />
                    </ChartCard>
                </div>
                { permissions.globalAdmin && (
                    <React.Fragment>
                        <div className="col-lg-6">
                            <DashboardTotalEnergyFlowCard/>
                        </div>
                        <div className="col-lg-6">
                            <DashboardTotalEnergyFlowCard
                                title="Live inzicht Aartsdijkweg 43 + 45"
                                installationIds={[
                                    9, // AF10K-TH
                                    37, // AF4K-ASL
                                    18, // H1-3.7-H
                                    21, // H3-5.0-E
                                    16, // GW10K-ET
                                    56, // SE4000H Beneden
                                    48, // SE5000H 400V Boven
                                    26, // SE5K-RWB48
                                    47, // CSS-OD
                                    69, // KSTAR
                                    74, // Deye
                                ]}
                            />
                        </div>
                        <div className="col-lg-6">
                            <DashboardTotalEnergyFlowCard
                                title="Live inzicht P. van der Kooij"
                                installationIds={[
                                    63, // P. van der Kooij
                                ]}
                            />
                        </div>
                        <div className="col-lg-6">
                            <DashboardTotalEnergyFlowCard
                                title="Live inzicht Stolk Potplanten"
                                installationIds={[
                                    75, // Stolk Potplanten
                                ]}
                            />
                        </div>
                        <div className="col-lg-6">
                            <DashboardTotalEnergyFlowCard
                                title="Live inzicht Delmo"
                                installationIds={[
                                    73, // Delmo
                                ]}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

export default React.memo(Dashboard);
