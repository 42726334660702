import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";
import {
    Chart
} from "react-charts";

import Helmet from "../../components/Helmet";
import ChartCard from "../../components/charts/ChartCard";
import ChartLoading from "../../components/charts/ChartLoading";
import ChartNoData from "../../components/charts/ChartNoData";
import NumberFormatter from "../../components/formatters/NumberFormatter";
import EuroFormatter from "../../components/formatters/EuroFormatter";

const initialStartDate = moment().subtract(2, "years").startOf("year").format("YYYY-MM-DD");
const initialEndDate = moment().format("YYYY-MM-DD");

function EnergyPriceDelta() {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);

    const [minimumPriceDelta, setMinimumPriceDelta] = useState(0.05);
    const [batteryCapacity, setBatteryCapacity] = useState(10);
    const [showDailyDataChart, setShowDailyDataChart] = useState(false);

    const fetchData = useCallback(async (inputStartDate, inputEndDate) => {
        setError(null);
        setData(null);
        if(inputStartDate === "" || inputEndDate === "") {
            return;
        }
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if(!dateRegex.test(inputStartDate) || !dateRegex.test(inputEndDate)) {
            setError("Ongeldige datum");
            return;
        }
        try {
            const response = await axios.post("/getEnergyPriceDelta", {
                startDate: moment(inputStartDate).toISOString(),
                endDate: moment(inputEndDate).toISOString(),
            });
            const processedData = Object.entries(response.data.data).map(([date, delta]) => ({
                key: date,
                value: delta,
            }))
            setData([{
                label: "Prijsverschil",
                data: processedData,
            }]);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, []);

    const triggerFetch = useCallback(async () => {
        await fetchData(startDate, endDate);
    }, [fetchData, startDate, endDate]);
    useEffect(() => {
        fetchData(initialStartDate, initialEndDate);
    }, [fetchData]);

    const monthlySumData = useMemo(() => {
        if(!data) {
            return null;
        }
        if(data.length === 0) {
            return [];
        }
        const monthlySum = data[0].data.reduce((acc, { key, value }) => {
            const month = moment(key).format("YYYY-MM");
            if(!acc[month]) {
                acc[month] = 0;
            }
            if(value >= minimumPriceDelta) {
                acc[month] += value;
            }
            return acc;
        }, {});
        return [{
            label: "Maandelijks totaal",
            data: Object.entries(monthlySum).map(([month, value]) => ({
                key: month,
                value,
            })),
        }];
    }, [data, minimumPriceDelta]);

    const yearlySumData = useMemo(() => {
        if(!data) {
            return null;
        }
        if(data.length === 0) {
            return [];
        }
        const yearlySum = data[0].data.reduce((acc, { key, value }) => {
            const year = moment(key).format("YYYY");
            if(!acc[year]) {
                acc[year] = 0;
            }
            if(value >= minimumPriceDelta) {
                acc[year] += value;
            }
            return acc;
        }, {});
        return [{
            label: "Jaarlijks totaal",
            data: Object.entries(yearlySum).map(([year, value]) => ({
                key: year,
                value,
            })),
        }];
    }, [data, minimumPriceDelta]);

    const primaryAxis = useMemo(() => ({
        getValue: (datum) => datum.key,
        formatters: {
            scale: (key) => {
                const date = moment(key);
                if(parseInt(date.format("DD"), 10) % 16 === 1) {
                    return date.format("DD-MM-YYYY");
                }
                return "";
            },
            tooltip: (key) => moment(key).format("DD-MM-YYYY"),
        },
    }), []);
    const primaryAxisMonthly = useMemo(() => ({
        getValue: (datum) => datum.key,
    }), []);
    const secondaryAxes = useMemo(() => [{
        getValue: (datum) => datum.value,
        hardMin: 0,
        formatters: {
            scale: (value) => (
                <EuroFormatter price={ value }/>
            ),
            tooltip: (value) => (
                <NumberFormatter
                    number={ value }
                    minimumFractionDigits={ 3 }
                />
            ),
        }
    }], []);

    return (
        <React.Fragment>
            <Helmet title="Energieprijs delta" />
            <Title preTitle="Overzicht">
                Energieprijs delta
            </Title>
            <div className="card mb-3">
                <div className="card-body">
                    <p className="card-text">
                        Let op! Dit is een voorspelling op basis van historische data. Hier gaan we uit van 1 laadcyclus per dag,
                        mits het prijsverschil tussen de laagste en hoogste prijs van de dag groter is dan het ingestelde minimum prijsverschil.
                    </p>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Instellingen berekening</h5>
                    <div className="d-flex flex-sm-row flex-column" style={{ gap: "16px" }}>
                        <div className="form-group flex-grow-1 mb-0" style={{ minWidth: "200px" }}>
                            <label htmlFor="startDate">Startdatum</label>
                            <input
                                type="date"
                                className="form-control"
                                id="startDate"
                                value={ startDate }
                                onChange={(event) => setStartDate(event.target.value)}
                            />
                        </div>
                        <div className="form-group flex-grow-1 mb-0" style={{ minWidth: "200px" }}>
                            <label htmlFor="endDate">Einddatum</label>
                            <input
                                type="date"
                                className="form-control"
                                id="endDate"
                                value={ endDate }
                                onChange={(event) => setEndDate(event.target.value)}
                            />
                        </div>
                        <div className="form-group flex-grow-1 mb-0" style={{ minWidth: "200px" }}>
                            <label htmlFor="minimumPriceDelta">Minimum prijsverschil</label>
                            <input
                                type="number"
                                className="form-control"
                                id="minimumPriceDelta"
                                value={ minimumPriceDelta }
                                step="0.01"
                                onChange={(event) => setMinimumPriceDelta(parseFloat(event.target.value))}
                            />
                        </div>
                        <div className="form-group flex-grow-1 mb-0" style={{ minWidth: "200px" }}>
                            <label htmlFor="batteryCapacity">Accucapaciteit in kWh</label>
                            <input
                                type="number"
                                className="form-control"
                                id="batteryCapacity"
                                value={ batteryCapacity }
                                onChange={(event) => setBatteryCapacity(parseFloat(event.target.value))}
                            />
                        </div>
                    </div>
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={triggerFetch}
                    >
                        Data verversen
                    </Button>
                    <div className="custom-control custom-checkbox mt-3">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="showDailyDataChart"
                            checked={ showDailyDataChart }
                            onChange={(event) => setShowDailyDataChart(event.target.checked)}
                        />
                        <label className="custom-control-label" htmlFor="showDailyDataChart">
                            Toon dagelijkse data
                        </label>
                    </div>

                </div>
            </div>
            { showDailyDataChart && (
                <ChartCard title="Energieprijs delta">
                    { error ? (
                        <Alert variant="danger">{ error }</Alert>
                    ) : !data ? (
                        <ChartLoading/>
                    ) : data.length === 0 ? (
                        <ChartNoData/>
                    ) : (
                        <Chart
                            options={{
                                data,
                                primaryAxis,
                                secondaryAxes,
                                getDatumStyle: (datum) => {
                                    if(datum.originalDatum.value < minimumPriceDelta) {
                                        return {
                                            color: "#ba1500"
                                        }
                                    }
                                    return {};
                                },
                            }}
                        />
                    )}
                </ChartCard>
            )}
            { showDailyDataChart && (
                <ChartCard title="Voorspelling dagelijkse verdiensten">
                    { error ? (
                        <Alert variant="danger">{ error }</Alert>
                    ) : !data ? (
                        <ChartLoading/>
                    ) : data.length === 0 ? (
                        <ChartNoData/>
                    ) : (
                        <Chart
                            options={{
                                data: data.map(({ label, data }) => ({
                                    label,
                                    data: data.map((datum) => ({
                                        key: datum.key,
                                        value: datum.value < minimumPriceDelta ? 0 : (datum.value * batteryCapacity),
                                    })),
                                })),
                                primaryAxis,
                                secondaryAxes,
                            }}
                        />
                    )}
                </ChartCard>
            )}
            <ChartCard title="Voorspelling maandelijkse verdiensten">
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !data ? (
                    <ChartLoading/>
                ) : data.length === 0 ? (
                    <ChartNoData/>
                ) : (
                    <Chart
                        options={{
                            data: monthlySumData.map(({ label, data }) => ({
                                label,
                                data: data.map((datum) => ({
                                    key: datum.key,
                                    value: datum.value * batteryCapacity,
                                })),
                            })),
                            primaryAxis: primaryAxisMonthly,
                            secondaryAxes,
                        }}
                    />
                )}
            </ChartCard>
            <ChartCard title="Voorspelling jaarlijkse verdiensten">
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !data ? (
                    <ChartLoading/>
                ) : data.length === 0 ? (
                    <ChartNoData/>
                ) : (
                    <Chart
                        options={{
                            data: yearlySumData.map(({ label, data }) => ({
                                label,
                                data: data.map((datum) => ({
                                    key: datum.key,
                                    value: datum.value * batteryCapacity,
                                })),
                            })),
                            primaryAxis: primaryAxisMonthly,
                            secondaryAxes,
                        }}
                    />
                )}
            </ChartCard>
        </React.Fragment>
    );
}

export default React.memo(EnergyPriceDelta);
