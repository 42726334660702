import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";

function ModeHistoryItem({ historyItem, startDate, endDate }) {
    const {
        fixedStartDate,
        fixedEndDate,
    } = useMemo(() => {
        return {
            fixedStartDate: moment.max(moment(historyItem.startDate), startDate),
            fixedEndDate: moment.min(moment(historyItem.endDate), endDate),
        }
    }, [historyItem, startDate, endDate]);

    const durationSeconds = useMemo(() => {
        return fixedEndDate.diff(fixedStartDate, 'seconds');
    }, [fixedStartDate, fixedEndDate]);

    const timeSinceStartDay = useMemo(() => {
        return fixedStartDate.diff(startDate, 'seconds');
    }, [fixedStartDate, startDate]);

    const totalSecondsInDay = useMemo(() => {
        return startDate.clone().endOf('day').diff(startDate.clone().startOf('day'), 'seconds');
    }, [startDate]);

    const width = (durationSeconds / totalSecondsInDay) * 100;
    const leftOffset = (timeSinceStartDay / totalSecondsInDay) * 100;

    return (
        <OverlayTrigger overlay={
            <Tooltip id={`tooltip-InveterModeHistory`}>
                <b>
                    { moment(fixedStartDate).format("HH:mm") } - { moment(fixedEndDate).format("HH:mm") }
                </b>
                <br/>
                { historyItem.mode.name }
                { historyItem.manualOverrideAuthor && (
                    <div className="text-muted">
                        Handmatig ingesteld door
                        <br/>
                        { historyItem.manualOverrideAuthor.name }
                    </div>
                )}
            </Tooltip>
        }>
            <div
                style={{
                    width: `${width}%`,
                    position: "absolute",
                    display: "inline-block",
                    left: `${leftOffset}%`,
                }}
            >
                <div
                    style={{
                        backgroundColor: historyItem.mode.color,
                        border: "1px solid white",
                        height: "20px",
                        borderRadius: "0.2rem",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    { historyItem.manualOverrideAuthor && (width > 4) && (
                        <React.Fragment>
                            <i
                                className="fa-solid fa-user text-white ml-1"
                                style={{ fontSize: "0.6rem" }}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
        </OverlayTrigger>
    )
}

function InverterModeHistoryChart({ inverter, history, startDate, endDate }) {
    return (
        <React.Fragment>
            { !history ? (
                <Loading/>
            ) : (
                <div style={{
                    width: "100%",
                    position: "relative",
                    height: "20px",
                    overflowX: "hidden"
                }}>
                    { history.map((historyItem) => (
                        <ModeHistoryItem
                            key={ historyItem.id }
                            historyItem={ historyItem }
                            startDate={ startDate }
                            endDate={ endDate }
                        />
                    ))}
                </div>
            )}
        </React.Fragment>
    )
}

function InstallationInverterModeHistoryChart({ installation, startDate, endDate }) {
    const [history, setHistory] = useState(null);
    const [error, setError] = useState(null);
    const [empty, setEmpty] = useState(false);

    useEffect(() => {
        if(!startDate || !endDate || !installation) {
            return;
        }
        const startDateFormat = startDate.tz("Europe/Amsterdam").toISOString();
        const endDateFormat = endDate.tz("Europe/Amsterdam").toISOString();
        setHistory(null);
        setError(null);
        setEmpty(false);
        axios.post("/getInstallationInverterModeHistory", {
            installationId: installation.id,
            startDate: startDateFormat,
            endDate: endDateFormat
        })
            .then((response) => {
                if(response.data.valid) {
                    setHistory(response.data.history);
                    setEmpty(response.data.history?.every(({ history }) => history.length === 0) ?? true);
                } else {
                    setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw.");
            });
    }, [installation, startDate, endDate]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">Sturing geschiedenis</h5>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !history ? (
                    <Loading/>
                ) : empty ? (
                    <p className="m-0">
                        Geen geschiedenis beschikbaar.
                    </p>
                ) : history.map(({ inverter, history }) => (
                    <InverterModeHistoryChart
                        key={ inverter.id }
                        inverter={ inverter }
                        history={ history }
                        startDate={ startDate }
                        endDate={ endDate }
                    />
                ))}
            </div>
        </div>
    )
}

export default React.memo(InstallationInverterModeHistoryChart);
