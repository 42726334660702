import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Button,
    ButtonGroup,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";
import isNullOrUndefined from "@gridsense/react-energy-flow/lib/utilities/isNullOrUndefined";

import Helmet from "../../components/Helmet";
import usePermissions from "../../hooks/usePermissions";
import InstallationsTable from "./InstallationsTable";
import InstallationsMap from "./InstallationsMap";

function Installations() {
    const permissions = usePermissions();

    const [installations, setInstallations] = useState(null);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const searchTimeoutRef = useRef();

    const [viewType, setViewType] = useState("list"); // "list" | "map"

    const fetchInstallations = useCallback(async (inputSearchQuery) => {
        setInstallations(null);
        setError(null);
        try {
            const response = await axios.post("/getInstallations", {
                searchQuery: inputSearchQuery
            });
            if(!isNullOrUndefined(response.data.searchQuery) && response.data.searchQuery !== inputSearchQuery) {
                return;
            }
            setInstallations(response.data.installations);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        }
    }, []);

    useEffect(() => {
        fetchInstallations("");
    }, [fetchInstallations]);

    const onSearchChange = useCallback(async (event) => {
        setSearchQuery(event.target.value);
        if(searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        if(event.target.value.trim().length === 0) {
            fetchInstallations(event.target.value);
            return;
        }
        searchTimeoutRef.current = setTimeout(() => {
            fetchInstallations(event.target.value);
        }, 500);
    }, [fetchInstallations]);

    return (
        <React.Fragment>
            <Helmet title="Installaties"/>
            <Title
                preTitle="Overzicht"
                preChildren={
                    permissions.manageInstallations && (
                        <div className="float-right pt-2">
                            <OverlayTrigger overlay={
                                <Tooltip id={`tooltip-add-installation`}>
                                    Installatie aanmaken
                                </Tooltip>
                            }>
                                <Link to="/installations/add" className="btn btn-primary ml-2">
                                    <i className="fa-solid fa-plus fa-fw"/>
                                </Link>
                            </OverlayTrigger>
                        </div>
                    )
                }
            >
                Installaties
            </Title>
            <div className="d-flex justify-content-end">
                <div className="form-group mb-4" style={{ maxWidth: "300px", width: "100%" }}>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Zoeken"
                        value={ searchQuery }
                        onChange={ onSearchChange }
                    />
                </div>
                <div className="ml-2">
                    <ButtonGroup>
                        <Button
                            variant={ viewType === "list" ? "secondary" : "outline-secondary" }
                            onClick={ () => setViewType("list") }
                        >
                            <i className="fa-solid fa-table"/>
                        </Button>
                        <Button
                            variant={ viewType === "map" ? "secondary" : "outline-secondary" }
                            onClick={ () => setViewType("map") }
                        >
                            <i className="fa-solid fa-map"/>
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
            { viewType === "list" ? (
                <InstallationsTable
                    installations={ installations }
                    searchQuery={ searchQuery }
                    error={ error }
                />
            ) : viewType === "map" && (
                <React.Fragment>
                    <hr className="mt-0"/>
                    <InstallationsMap
                        installations={ installations }
                        searchQuery={ searchQuery }
                        error={ error }
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default React.memo(Installations);
