import React from "react";
import {
    ErrorBoundary
} from "@sentry/react";
import {
    Alert
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

function GridSenseChart({ ...props }) {
    return (
        <ErrorBoundary fallback={
            <Alert variant="danger">Er ging iets fout. Probeer het later opnieuw.</Alert>
        }>
            <Chart
                {...props}
            />
        </ErrorBoundary>
    );
}

export default React.memo(GridSenseChart);
