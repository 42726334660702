import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import InstallationAddGatewayModal from "./modal/InstallationAddGatewayModal";
import InstallationGatewayRestartSoftwareModal from "./modal/InstallationGatewayRestartSoftwareModal";
import InstallationGatewaySettingsModal from "./modal/gateway-settings/InstallationGatewaySettingsModal";
import InstallationGatewayCard from "./components/device-cards/InstallationGatewayCard";

function InstallationDetailGateways({ installation }) {
    const [gateways, setGateways] = useState(null);
    const [error, setError] = useState(null);

    const [showAddGatewayModal, setShowAddGatewayModal] = useState(false);

    const [showRestartSoftwareModal, setShowRestartSoftwareModal] = useState(false);
    const [restartSoftwareModalGateway, setRestartSoftwareModalGateway] = useState(null);

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [settingsModalGateway, setSettingsModalGateway] = useState(null);

    const refreshGateways = useCallback(() => {
        if(!installation) {
            return;
        }
        setError(null);
        axios.post("/getGatewaysForInstallation", { installationId: installation.id })
            .then((response) => {
                if(response.data.valid) {
                    setGateways(response.data.gateways);
                } else {
                    setError("Er is iets fout gegaan. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            });
    }, [installation]);

    useEffect(() => {
        refreshGateways();
        const interval = setInterval(refreshGateways, 5000);
        return () => clearInterval(interval);
    }, [refreshGateways]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        );
    }
    if(!gateways) {
        return (
            <Loading/>
        )
    }
    return (
        <React.Fragment>
            <InstallationAddGatewayModal
                installation={ installation }
                show={ showAddGatewayModal }
                handleClose={ () => setShowAddGatewayModal(false) }
                refreshDevices={ refreshGateways }
            />
            <InstallationGatewayRestartSoftwareModal
                show={ showRestartSoftwareModal }
                handleClose={ () => setShowRestartSoftwareModal(false) }
                gateway={ restartSoftwareModalGateway }
            />
            <InstallationGatewaySettingsModal
                show={ showSettingsModal }
                handleClose={ () => setShowSettingsModal(false) }
                gateway={ settingsModalGateway }
                installation={ installation }
            />
            { gateways.length === 0 ? (
                <div className="text-center">
                    <h1><i className="fa-solid fa-router"/></h1>
                    <h4>Geen gateways</h4>
                    <p className="mb-0">Er zijn nog geen gateways toegevoegd aan deze installatie.</p>
                    <p>Gateways zijn het communicatie middel tussen GridSense en de omvormer.</p>
                    <Button variant="primary" onClick={ () => setShowAddGatewayModal(true) }>
                        <i className="fa-solid fa-plus mr-2"/>
                        Gateway toevoegen
                    </Button>
                </div>
            ) : (
                <React.Fragment>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="mb-3" size="sm" onClick={ () => setShowAddGatewayModal(true) }>
                            <i className="fa-solid fa-plus mr-2"/>
                            Gateway toevoegen
                        </Button>
                    </div>
                    <div className="row">
                        { gateways.map((gateway) => (
                            <div className="col-lg-6" key={ gateway.id }>
                                <InstallationGatewayCard
                                    gateway={ gateway }
                                    installation={ installation }
                                    onClickRestart={ () => {
                                        setRestartSoftwareModalGateway(gateway);
                                        setShowRestartSoftwareModal(true);
                                    }}
                                    onClickSettings={ () => {
                                        setSettingsModalGateway(gateway);
                                        setShowSettingsModal(true);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(InstallationDetailGateways);
